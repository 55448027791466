import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import ProdutosScreen from "./pages/Produtos";
import Catalogo from "./pages/Catalogo";
import AdminPage from "./pages/Admin";
import Login from "./components/Admin/Login/Login";

const Rotas = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Rota principal que renderiza o Home */}
        <Route path="/" element={<Home />} />
        
        {/* Outras rotas específicas */}
        <Route path="/produtos" element={<ProdutosScreen />} />
        <Route path="/catalogo" element={<Catalogo />} />
        <Route path="/_admin" element={<AdminPage />} />
        <Route path="/login" element={<Login />} />
        
        {/* Rota catch-all, que captura qualquer rota não especificada */}
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Rotas;
